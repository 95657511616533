<template lang="pug">
v-app#login.grey.darken-4
  v-content
    v-container(fluid, fill-height)
      v-layout(align-center, justify-center)
        v-flex(xs12, sm4, md4, lg4)
          .layout.column.align-center.mb-4
            img(:src="logoColor", alt='Automatic 4', width='250')
          form(@submit.prevent="login", data-vv-scope="login")
            v-card.elevation-1.pa-4
              v-card-text
                v-layout(row, wrap).pa-0
                  v-flex.md12
                    v-text-field(append-icon='person', color="primary", label='Código', v-validate="'required'", required, :error-messages="errors.collect('codigo')", data-vv-name="codigo", type="number", v-model='model.codigo')
                  v-flex.md12
                    v-text-field(append-icon='lock', color="primary", label="Senha", v-validate="'required'", required, :error-messages="errors.collect('senha')", data-vv-name="senha", type='password', v-model='model.senha')
                  
              v-card-actions
                v-layout(row, wrap).pa-0
                  v-flex.xs12.text-xs-center
                    v-btn(block, color='original_color', type="submit", :loading='loading').elevation-5.black--text Entrar
              v-card-actions
                v-layout(row, wrap).pa-0
                  v-flex.md12.text-xs-center.mt-0
                    v-btn(flat, color="black", type="button", @click="dialogSenha = true").font-weight-bold.text-none.body-1.pa-2.mx-0.my-0.ma-0.pa-2 Esqueci minha senha

  v-footer(color="white").mb-3.mt-0
    v-layout(row, wrap)
      v-flex.text-xs-center
        span.body-2 AUTOMATIC 4 © {{ new Date().getFullYear() }}
        span.body-2.pa-2  - 
        span.body-2.mr-1 Desenvolvido por 
            a(href="http://labcinco.com", target="_blank", style="text-decoration:none;").font-weight-bold.black--text Lab Cinco
          

  v-dialog(v-model="dialogSenha", width="auto", max-width="500px")
    form(@submit.prevent="recovery" data-vv-scope="recovery")
      v-card
        v-card-title
          v-layout(row, wrap)
            v-flex.md11
              span.headline Recuperar Senha
            v-flex.md1.text-xs-right
              v-tooltip(top)
                v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogSenha = false;").pa-0.mx-0.my-0.ma-0
                  v-icon close
                span Fechar
        //- v-divider
        v-card-text
          v-layout(wrap)
            v-flex.xs12.pa-1
              v-text-field(append-icon='person', data-vv-scope="formRecovery", color="gray", label='Código', v-validate="'required'", required, :error-messages="errors.collect('codigo')", data-vv-name="codigo", type="text", v-model='model.codigo')
        //- v-divider
        v-card-actions
          v-layout(row, wrap).pa-1
            v-flex.xs12.text-xs-right
              v-btn(type="button", flat, color="gray darken-1", @click.native="dialogSenha = false") Fechar
              v-btn(type="submit", color="original_color", :loading="loading", :disabled="loading") Enviar
</template>

<script>
import Hashes from 'jshashes';
import logoColor from '@/assets/images/logo.png';
// eslint-disable-next-line
const SHA512 = new Hashes.SHA512;

export default {
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    loading: false,
    loading: false,
    model: {},
    dialogSenha: false,
    logoColor: logoColor
  }),
  methods: {
   async sendLogin(model) {
      const vm = this;
      try {
        const response = await vm.$axios.post("/login", { codigo: model.codigo, senha: SHA512.hex(model.senha) });
        vm.$store.dispatch("setUser", response.data);
        window.getApp.$emit("APP_LOGIN_SUCCESS");
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : "Ocorreu um erro ao fazer o login. Tente novamente!";
        window.getApp.$emit("APP_ALERT", { color: "red", text: err });
        vm.loading = false;
      }
    },
    async sendRecovery(model) {
      const vm = this;
      try {
        const response = await vm.$axios.put("/password/reset", { codigo: model.codigo });
        vm.dialogSenha = false;
        window.getApp.$emit("APP_ALERT", { color: "success", text: `Uma nova senha foi gerada e enviada para o email cadastrado.` });
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : "Ocorreu um erro ao recuperar a senha. Tente novamente!";
        window.getApp.$emit("APP_ALERT", { color: "red", text: err });
        vm.loading = false;
      }
    },
    async login() {
      const vm = this;
      try {
        vm.loading = true;
        const result = await vm.$validator.validateAll('login');
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm.sendLogin(vm.model);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async recovery() {
      const vm = this;
      try {
        vm.loading = true;
        const result = await vm.$validator.validateAll("formRecovery");
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm.sendRecovery(vm.model);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    }
  }
};
</script>
<style scoped lang="css">
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
  }
</style>
